<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-02 18:46:10
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-29 17:07:46
-->
<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="门店">
              <a-input v-model="searchData.storeName" placeholder="门店名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
<!--        <a-button type="primary" @click="toHandler('store')">申请配额</a-button>-->
<!--        <a-button-->
<!--          v-if="isSubmit && selectedRows.length > 0 && selectedRows[0].status == 0"-->
<!--          type="primary"-->
<!--          @click="toHandler('submit_audio')"-->
<!--          >提交审核</a-button-->
<!--        >-->
<!--        <a-button-->
<!--          v-if="isAudit && selectedRows.length > 0 && selectedRows[0].status == 1"-->
<!--          type="primary"-->
<!--          @click="toHandler('audio')"-->
<!--          >审核配额</a-button-->
<!--        >-->
<!--        <a-button-->
<!--          v-if="selectedRows.length > 1  && selectedRows[0].status == 1"-->
<!--          type="primary"-->
<!--          @click="toHandlerAll"-->
<!--          >批量审核</a-button-->
<!--        >-->
<!--        22-01-13-->
<!--        <a-button v-if="isCheck" type="primary" @click="toHandler('check_record')">查看申请记录</a-button>-->
<!--        <a-button type="primary" @click="toHandler('store')">申请临时配额</a-button>-->
        <a-button v-if="isAllocation && selectedRows.length == 1 && selectedRows[0].status == 2 && selectedRows[0].quotaStatus == 1" type="primary" @click="toHandler('staff_allocation')">分配临时配额</a-button>
        <a-button v-if="isCheckAllocation && selectedRows.length == 1 && selectedRows[0].status == 2" type="primary" @click="toHandler('check_allocation')">查看分配记录</a-button>
        <!-- <a-button type="primary" @click="toHandler('edit')">分配配额</a-button> -->
        <!-- <a-button type="primary" @click="toHandler('del')">删除</a-button> -->
<!--        <a-button v-if="isAudit && selectedRows.length > 0 && selectedRows[0].status == 1" type="default" @click="toHandler('storeRollbackStatus')" icon="rollback">撤审</a-button>-->
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="status" slot-scope="text">
          <span> {{ text | status }} </span>
        </span>
        <span slot="quotaStatus" slot-scope="text">
          <span v-if="text == 1">未过期</span>
          <span v-if="text == 2">已过期</span>
        </span>
        <span slot="startingTime" slot-scope="text,row">
           <span v-if="row.startingTime != null && row.expireDate">{{row.startingTime}} 至 {{row.expireDate}}</span>
        </span>
        <span slot="useNum" slot-scope="text,row">
<!--           <span>{{row.remainingNum + row.useNum}}</span>-->
           <span>{{row.useNum}}</span>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerPreOrdersNum-edit-modal
        ref="DealerPreOrdersNumEditModal"
        @reload="getData"
      ></DealerPreOrdersNum-edit-modal>
      <!-- 审核配额 -->
      <AudioPage ref="AudioPage" @reload="getData"></AudioPage>
      <!-- 分配配额 -->
      <AllocationStoreRuota ref="AllocationStoreRuota" @reload="getData"></AllocationStoreRuota>
      <!-- 查看申请记录 -->
      <ApplicationRecord ref="ApplicationRecord"></ApplicationRecord>
      <!-- 查看分配记录 -->
      <AllocationRecord ref="AllocationRecord"></AllocationRecord>
      <!-- <a-modal v-model="visibleAll" title="批量审核" @ok="handleOkAll"  @cancel="handleCanceAll" :maskClosable="false" width="40%">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
          <a-form-model-item label="是否通过">
            <a-radio-group v-model="statusAll">
              <a-radio :value="4"> 通过 </a-radio>
              <a-radio :value="3"> 拒绝 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea v-model="rejectReason"></a-textarea>
          </a-form-model-item>
        </a-form-model>
    </a-modal> -->
    <a-modal title="批量审核" width="30%" :visible="visibleAll" :footer="null" @cancel="visibleAll = false" :maskClosable="false">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-textarea placeholder="审核备注" v-model="verifyRemark"></a-textarea>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="handleCanceAll">取消</a-button>
        <a-button type="danger"  :loading="loadingAll"  @click="onAuditAll('refuse')">拒绝</a-button>
        <a-button type="primary" :loading="loadingAll"  @click="onAuditAll('audit')">通过</a-button>
      </div>
    </a-modal>
    </div>
  </a-card>
</template>

<script>
import {columns, storeColumns} from './components/colums.js'
import DealerPreOrdersNumEditModal from './components/DealerPreOrdersNumEditModal.vue'
import {delDealerPreOrdersNum, storeQuotaList, storeRollbackStatus} from './api/DealerPreOrdersNumApi'
import AudioPage from './components/AudioPage.vue'
import ApplicationRecord from './components/ApplicationRecord.vue'
import {checkPermission} from '@/utils/permissions'
import AllocationStoreRuota from './components/AllocationStoreRuota.vue'
import AllocationRecord from './components/AllocationRecord.vue'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerPreOrdersNum',
  components: {
    DealerPreOrdersNumEditModal,
    AudioPage,
    ApplicationRecord,
    AllocationStoreRuota,
    AllocationRecord
  },
  data() {
    return {
      columns: storeColumns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      visibleAll:false,
      loadingAll:false,
      dataList:[],
      verifyRemark:'',
      isCheck: checkPermission('allot:store:quota:check'),
      isSubmit: checkPermission('allot:store:quota:submit'),
      isAudit: checkPermission('allot:store:quota:audit'),
      isAllocation: checkPermission('allot:store:quota:allocation'),
      isCheckAllocation: checkPermission('allot:store:quota:check_allocation'),
    }
  },

  filters: {
    status(type) {
      const typeMap = {
        0: '草稿',
        1: '待审核',
        2: '审核通过',
        3: '审核拒绝',
      }
      return typeMap[type]
    },
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    toHandlerAll() {
      let everyResult = this.selectedRows.every((item) => {
        return item.status === 1
      })
      if (everyResult) {
        this.visibleAll = true;
        this.dataList = this.selectedRows
      }else{
        this.$notification.error({ message: '请选择相同待审核记录' })

      }
    },
      // 审核
    onAuditAll(name) {
      const _this = this
      _this.loadingAll = true
      let query = {
        authStatus: name == 'audit' ? 2 : 3,
        verifyRemark: _this.verifyRemark,
      }
      query.ids = _this.dataList.map((item) => item.id)
      console.log(query)
      _this.axios.post('/api/base/quota/storeApplicationRecord/updateList', query).then(res => {
          if(res.code == 200) {
             setTimeout(() => {
              _this.$notification.success({ message: res.message })
              _this.getData()
              _this.verifyRemark = ''
              _this.visibleAll = false;
              _this.loadingAll = false;
         }, 3000);
          }else {
              _this.$notification.error({ message: res.message })
              _this.visibleAll = false;
              _this.getData()
          }
      })

    },
     handleCanceAll(){
      this.visibleAll = false
      this.verifyRemark = ''
      this.loadingAll = false
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRows = []
      this.selectedRowKeys = []
      storeQuotaList({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
      this.loadingAll = false
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'store') {
        _this.$refs.DealerPreOrdersNumEditModal.setRowData({}, 'store')
        return
      } else if (name == 'staff_allocation') {
        _this.$refs.AllocationStoreRuota.setRowData(_this.selectedRows[0], 'staff_allocation')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerPreOrdersNumEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerPreOrdersNum(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'storeRollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await storeRollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'audio':
          _this.$refs.AudioPage.show(_this.selectedRows[0], 'store')
          break
        case 'submit_audio':
          _this.$confirm({
            title: '确认提交审核？',
            onOk: () => {
              _this.axios
                .post(`/api/base/quota/storeApplicationRecord/updateData`, {
                  status: 1,
                  id: this.selectedRows[0].id,
                  storeId: this.selectedRows[0].storeId,
                })
                .then((res) => {
                  _this.$message.success(res.message)
                  _this.getData()
                })
                .catch((err) => {})
            },
          })
          break
        case 'check_record':
          _this.$refs.ApplicationRecord.getList(_this.selectedRows[0], 'store')
          break
        case 'check_allocation':
          _this.$refs.AllocationRecord.getList(_this.selectedRows[0], 'staff')
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
