<!--
 * @Descripttion: 临时配额分配记录
 * @version:
 * @Author: hutian
 * @Date: 2021-07-17 10:46:27
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-29 17:21:30
-->
<template>
  <div class="allocation_record">
    <a-modal
      :maskClosable="false"
      style="top: 8px"
      :width="1000"
      :title="handle === 'store' ? `【门店】临时配额分配记录` : `【员工】临时配额分配记录`"
      v-model="visible"
      :footer="null"
    >
      <a-table
        size="small"
        :data-source="tableData"
        bordered
        :rowKey="(record) => record.id"
        :pagination="page"
        @change="
          (p) => {
            page = p
            toSearch(2)
          }
        "
        :customRow="changeTableRow"
      >
        <a-table-column title="员工名称" data-index="managerName" align="center" v-if="handle == 'staff'">
        </a-table-column>
        <a-table-column title="门店名称" data-index="storeName" align="center" v-if="handle == 'store'">
        </a-table-column>
        <a-table-column title="分配额度" data-index="temporaryQuota" align="center">
        </a-table-column>
<!--        <a-table-column title="审核状态" data-index="status" align="center" v-if="handle == 'staff'">-->
<!--          <template slot-scope="text"> {{text | status}} </template>-->
<!--        </a-table-column>-->
<!--        <a-table-column title="审核状态" data-index="status" align="center" v-if="handle == 'store'">-->
<!--          <template slot-scope="text"> {{text | statusStore}} </template>-->
<!--        </a-table-column>-->
<!--        <a-table-column title="申请原因" data-index="remarks" align="center">-->
<!--        </a-table-column>-->
        <a-table-column title="有效期" data-index="" align="center">
          <template slot-scope="row"> {{row.startingTime}} 至 {{row.expireDate}} </template>
        </a-table-column>
        <a-table-column title="创建时间" data-index="createTime" align="center">
        </a-table-column>
        <a-table-column title="创建人" data-index="createUser" align="center">
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'allocation_record',
  data() {
    return {
      visible: false,
      title: '门店',
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        size: 'small',
      },
      handle: 'store',
    }
  },

  filters: {
    status(num) {
      const typeMap = {
        1: '草稿',
        2: '待审核',
        3: '审核通过',
        4: '审核拒绝'
      }
      return typeMap[num]
    },
    statusStore(type) {
      const typeMap = {
        0: '草稿',
        1: '待审核',
        2: '审核通过',
        3: '审核拒绝',
      }
      return typeMap[type]
    },
    validPeriod(num) {
      const typeMap = {
        1: '未过期',
        2: '已过期'
      }
      return typeMap[num]
    }
  },

  computed: {},

  created() {},

  methods: {
    // 获取数据
    getList(item,handle) {
      this.handle = handle
      this.title = item.storeName
      this.visible = true
      if(handle == 'store'){
        this.axios.get(`/api/base/quota/storeApplicationRecord/getStoreApplicationByDealerId/${item.id}`).then((res) => {
          this.tableData = res.body
        })
      }

      if(handle == 'staff'){
        this.axios.get(`/api/base/quota/storeStaffApplicationRecord/getStoreStaffApplicationByStoreId/${item.id}`).then((res) => {
          this.tableData = res.body
        })
      }
    },

    changeTableRow(record) {
      return {
        on: {
          dblclick: (e) => {
            this.visible = false
            this.$emit('update:value', record.customerName)
            this.$emit('select', record)
            this.$emit('change')
          },
        },
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>