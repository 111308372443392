<!--
 * @Descripttion: 经销商分配临时配额给门店
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-17 16:40:47
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-29 13:59:58
-->
<template>
  <a-modal
    title="分配临时配额"
    style="top: 8px"
    :width="600"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-model-item label="总配额">
        <span>{{rowData.temporaryQuota}}</span>
        <span style="padding-left: 50px">已分配【{{rowData.temporaryQuota - rowData.remainingNum}}】</span>
        <span style="padding-left: 50px">剩余可分配【{{rowData.remainingNum}}】</span>
      </a-form-model-item>
      <a-form-model-item label="门店" prop="storeId" v-if="handle == 'store_allocation'">
        <a-select v-model="rowData.storeId" placeholder="请选择">
          <a-select-option :value="item.id" v-for="(item, index) in storeList" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="员工" prop="staffId" v-if="handle == 'staff_allocation'">
        <a-select v-model="rowData.staffId" placeholder="请选择">
          <a-select-option :value="item.id" v-for="(item, index) in staffList" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="分配临时配额" prop="allocationNum">
        <a-input-number :min="1" :max="rowData.remainingNum" v-model="rowData.allocationNum" placeholder="分配临时配额"></a-input-number>
      </a-form-model-item>
<!--      <a-form-model-item label="有效期">-->
<!--        <span>{{rowData.startingTime}} 至 {{rowData.expireDate}}</span>-->
<!--        <DateRange :startTime.sync="rowData.startingTime" :endTime.sync="rowData.expireDate"></DateRange>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="备注">
        <a-textarea placeholder="备注" v-model="rowData.remark"></a-textarea>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  components: {},

  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        storeId: [{ required: true, message: '请选择门店', trigger: 'blur' }],
        staffId: [{ required: true, message: '请选择员工', trigger: 'blur' }],
        allocationNum: [{ required: true, message: '请输入分配配额', trigger: 'blur' }],
      },
      storeList: [],
      staffList: []
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.rowData = row
      this.$set(this.rowData, 'allocationNum', row.remainingNum);
      if (handle == 'store_allocation') {
        this.axios.get(`/api/base/store/dealerStore/list?pageSize=99999`).then((res) => {
          this.storeList = res.body.records
        })
      }
      if (handle == 'staff_allocation') {
        this.axios.get(`/api/base/achievements/dealerAchievements/manager/choose?storeId=${this.rowData.storeId}`).then(res => {
          this.staffList = res.body
        })
      }
    },

    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        if (this.handle == 'store_allocation') {
          this.axios
            .post(`/api/base/quota/storeApplicationRecord/allocation`, this.rowData)
            .then((res) => {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
              this.rowData = {}
              this.storeList = []
            })
            .catch((err) => {
              this.$notification.error({ message: res.message })
            })
        }

        if (this.handle == 'staff_allocation') {
          this.axios
            .post(`/api/base/quota/storeStaffApplicationRecord/allocation`, this.rowData)
            .then((res) => {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
              this.rowData = {}
              this.staffList = []
            })
            .catch((err) => {
              this.$notification.error({ message: res.message })
            })
        }

      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
