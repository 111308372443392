<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-19 10:05:28
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-28 20:01:35
-->
<template>
  <div class="quota_audio">
    <a-modal
      v-model="visible"
      style="top: 8px"
      :width="600"
      :maskClosable="false"
      title="审核配额"
      :confirmLoading="confirmLoading"
      @ok="onSubmit"
    >
      <a-form-model layout="horizontal" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item label="申请配额" v-if="this.handle == 'staff'">
          <a-input placeholder="申请配额" v-model="rowData.temporaryQuota" disabled></a-input>
        </a-form-model-item>
        <a-form-model-item label="实际配额" v-if="statusRadio == 2 && handle == 'staff'">
          <a-input placeholder="请输入金额" v-model="rowData.actualQuota" 
          onkeyup="value=value.replace(/[^\d]/g,'')"
          onblur="value=value.replace(/[^\d]/g,'')"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="是否通过">
          <a-radio-group v-model="rowData.status" @change="onStatusChange">
            <a-radio :value="2"> 通过</a-radio>
            <a-radio :value="3"> 拒绝</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="有效期" v-show="statusRadio == 2 && handle == 'staff'">
          <RangePicker
            :startTime.sync="rowData.startingTime"
            :endTime.sync="rowData.expireDate"
            :show-time="true"
            :disabled-date="disabledDate"
          ></RangePicker>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea placeholder="备注" v-model="rowData.auditReason"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      statusRadio: 2,
      visible: false,
      rowData: {},
      confirmLoading: false,
      handle: '',
    }
  },

  computed: {},

  created() {
  },

  methods: {
    show(row, name) {
      this.visible = true
      this.handle = name
      this.rowData = {}
      this.$set(this.rowData, 'status', 2)
      this.$set(this.rowData, 'id', row.id)
      this.$set(this.rowData, 'dealerId', row.dealerId)
      this.rowData = row
      this.rowData.temporaryQuota = row.temporaryQuota
    },
    onStatusChange(val) {
      this.statusRadio = val.target.value
    },

    disabledDate(current) {
      return current && current == moment()
    },

    // 提交审核
    onSubmit() {
      this.confirmLoading = true
      if (this.handle == 'store') {
        this.axios
          .post(`/api/base/quota/storeApplicationRecord/updateData`, this.rowData)
          .then((res) => {
            this.$message.success(res.message)
            this.confirmLoading = false
            this.visible = false
            this.$emit('reload')
          })
          .catch((err) => {
            this.confirmLoading = false
            this.$emit('reload')
          })
      }

      if (this.handle == 'staff') {
        this.axios
          .post(`/api/base/quota/storeStaffApplicationRecord/updateData`, this.rowData)
          .then((res) => {
            this.$message.success(res.message)
            this.confirmLoading = false
            this.visible = false
            this.$emit('reload')
          })
          .catch((err) => {
            this.confirmLoading = false
            this.$emit('reload')
          })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>